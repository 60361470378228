@mixin ss-dialog-theme($theme) {
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, ss-foreground);
    mat-dialog-actions {
        button {
            color: mat-color($accent);
        }
    }
    mat-dialog-content {
        color: mat-color($foreground, secondary-text);
    }
}
