html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

$mdFontFamily: Roboto,
'Helvetica Neue',
sans-serif !default;
html {
    font-family: $mdFontFamily;
}
